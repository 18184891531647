<!--
 * @Date: 2023-10-26 14:10:12
 * @LastEditTime: 2024-01-21 18:24:59
 * @Description: file content
-->
<template>
  <div class="digitalHuman">
    <div class="centre-top">
      <div class="left-1">
        <div
          :class="[isDigital === '2D' && 'topColor', 'mr-40', 'pointer']"
          @click="switchDigital('2D')"
        >
          {{ titleText.title }}
        </div>
        <div
          :class="[
            SessionType && 'colorWhite',
            isDigital === '3D' && 'topColor',
            'pointer',
          ]"
          @click="switchDigital('3D')"
        >
          {{ titleText.title1 }}
        </div>
      </div>
    </div>
    <video
      id="video3D"
      src="https://vr-hotfix.s3-ap-east-1.amazonaws.com/video/vr_movie.mp4"
      autoplay="autoplay"
      loop="loop"
    ></video>
  </div>
</template>

<script>
export default {
  props: ['isDigital', 'SessionType', 'switchDigital', 'titleText'],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  // 监听属性
  watch: {},
  // 计算属性
  computed: {},
};
</script>
<style scoped>
.digitalHuman {
  flex: 1;
  height: 100%;
  overflow: hidden;
  border-radius: 0.426667rem;
}
.digitalHuman .centre-top {
  position: absolute;
  top: 28px;
  left: 40px;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: PingFangSC-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  margin-bottom: 32px;
}
.left-1 {
  display: flex;
}
.mr-40 {
  margin-right: 40px;
}
.topColor {
  color: #6c38e0;
}
#video3D {
  width: 100%;
  aspect-ratio: 53%;
}
.pointer {
  cursor: pointer;
}
</style>
