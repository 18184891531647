<template>
  <div class="loding" v-if="isShow" :class="className">
    <div class="loding_center">
      <!-- <div class="loading_circle"></div> -->
      <div class="loding_flex" v-if="text.text">
        <img src="../../assets/img/aiVideo/accomplish.png" alt="" />
        <span>{{ text.text }}</span>
      </div>

      <div v-if="text.text2" class="loding_flex" :class="!step2 && 'span'">
        <i v-if="!step2" class="el-icon-loading"></i>
        <img v-else src="../../assets/img/aiVideo/accomplish.png" alt="" />
        <span>{{ text.text2 }}</span>
      </div>
      <div v-if="text.text3" class="loding_flex" :class="!step3 && 'span'">
        <i v-if="!step3" class="el-icon-loading"></i>
        <img v-else src="../../assets/img/aiVideo/accomplish.png" alt="" />
        <span>{{ text.text3 }}</span>
      </div>

      <div class="loding_flex" :class="isShow && 'span'" v-if="text.finish">
        <i v-if="isShow" class="el-icon-loading"></i>
        <img v-else src="../../assets/img/aiVideo/accomplish.png" alt="" />
        <span>{{ text.finish }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isShow', 'text', 'className'],
  data() {
    return {
      step2: false,
      step3: false,
    };
  },

  mounted() {},
  methods: {},
  // 监听属性
  watch: {
    isShow: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$set(this, 'step2', true);
            }, 1000);
            setTimeout(() => {
              this.$set(this, 'step3', true);
            }, 2000);
          });
        } else {
          this.step2 = false;
          this.step3 = false;
        }
      },
      immediate: true,
    },
  },
  // 计算属性
  computed: {},
};
</script>
<style scoped>
.loding {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  border-radius: 16px;
  background: #9c9999;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading_circle {
  width: 24px;
  height: 24px;
  border: 2px solid #6c38e0;
  border-top-color: transparent;
  border-radius: 100%;
  margin-bottom: 10px;
  animation: circle infinite 0.75s linear;
}
@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* .loding_center {
  display: flex;

} */
.loding_flex {
  display: flex;
  align-items: center;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0px;

  /* 文本/语音数据转换 */
  color: #6c38e0;

  /* 模型输入数据准备…模型加载照片驱动文件生成 */
}
.loding_flex img {
  width: 16px;
  height: 16px;

  margin-right: 5px;
}
.loding_flex i {
  width: 16px;
  height: 16px;

  margin-right: 5px;
}
.span {
  color: #5d5f6c;
}
.className {
  /* background: rgba(255, 255, 255, 0.8); */
  position: relative;
  width: 100%;
  height: auto;
  background: none;
  /* top: 100%; */
  bottom: 30px;
  left: 50%;
  transform: translateX(-40%);
}
</style>
