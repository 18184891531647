<template>
  <!-- 产品侧边栏 -->
  <div class="img-list">
    <div class="img-list-top">
      <img
        src="../../assets/img/aiVideo/figure.png"
        width="20"
        height="20"
        alt=""
      />
      <span :class="[SessionType && 'colorWhite']">人物照片</span>
    </div>
    <el-upload
      class="avatar-uploader"
      :action="action"
      :headers="headers"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
      <div class="avatar-uploader-icon">
        <div class="flex-clu">
          <img src="../../assets/img/aiVideo/uploading.png" />
          <span>自定义上传</span>
        </div>
      </div>
    </el-upload>
    <div class="case">
      <i
        class="case-bnt el-icon-arrow-up pointer"
        @click="clickUpDown('up')"
      ></i>

      <div class="divImg" ref="divImg">
        <div class="imgData" ref="imgData">
          <img
            v-for="(item, inx) in imgData"
            :key="inx"
            :src="item"
            alt=""
            width="130"
            height="130"
            class="pointer"
            @click="clickImage(item, inx)"
            :class="imgIndex === inx ? 'imgBorder' : ''"
          />
        </div>
      </div>

      <i
        class="case-bnt el-icon-arrow-down pointer"
        @click="clickUpDown('down')"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [
    'imgData',
    'action',
    'SessionType',
    'imageUrl',
    'setSelectImgUrl',
    'handleAvatarSuccess',
    'loading',
  ],
  data() {
    return {
      refDiv: null,
      offsetTop: 0,
      imgIndex: 0,
      scrollDistance: 0,
      headers: {
        isSample: '0',
        token: localStorage.getItem('tokenKey')
          ? localStorage.getItem('tokenKey')
          : '',
      },
    };
  },
  mounted() {},
  methods: {
    // 点击
    clickUpDown(action) {
      const unitHeight = 130 * 4; // 一次滑动距离
      // 现在距离
      const top = this.$refs.divImg.scrollTop;
      if (action == 'up') {
        let hei = top - unitHeight;
        if (hei <= 0) {
          hei = 0;
        }
        this.$refs.divImg.scroll({
          top: hei,
          behavior: 'smooth',
        });
      } else if (action == 'down') {
        this.$refs.divImg.scroll({
          top: top + unitHeight,
          behavior: 'smooth',
        });
      }
    },

    // 限制图片
    beforeAvatarUpload(file) {
      const validFormats = ['image/jpeg', 'image/png', 'image/jpg'];
      const isFormatValid = validFormats.includes(file.type);
      if (!isFormatValid) {
        this.$message.error({
          message: '上传头像图片只能是 JPG、PNG 或 JPEG 格式!',
          center: true,
          offset: 100,
        });
      }

      return isFormatValid;
    },
    clickImage(item, inx) {
      if (this.loading) {
        return;
      }
      this.setSelectImgUrl(item, inx);
      this.imgIndex = inx;
    },
  },
  // 监听属性
  watch: {},
  // 计算属性
  computed: {},
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.img-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.img-list-top {
  font-family: PingFangSC-Medium;
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  margin-bottom: 12px;
  color: #000108;
}
.img-list-top img {
  margin-right: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 130px;
  height: 130px;
  border: 1px dashed #5d5f6c;
  line-height: 130px;
  text-align: center;
}
.avatar-uploader-icon:hover {
  border: 1px dashed #000000;
}
.avatar-uploader-icon .flex-clu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 16px;

  font-family: PingFangSC-Regular;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: 0px;

  color: #000108;
}
.avatar-uploader-icon .flex-clu img {
  margin-bottom: 7px;
}
.avatar {
  width: 130px;
  height: 130px;
  display: block;
}
.case {
  display: flex;
  flex-direction: column;
  height: calc(100% - 160px);
}
.case-bnt {
  display: flex;
  justify-content: center;
  font-size: 16px;
  align-items: center;
  width: 130px;
  height: 24px;
  border-radius: 6px;
  margin: 12px 0;
  /* background: #ffffff; */
  transition: all 0.3s;
}
.case-bnt:hover {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
}
.divImg {
  overflow: hidden;
  overflow-y: auto;
  transition-property: all;
  transition-timing-function: linear;
  transition-duration: 1s;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  height: 100%;
}
.divImg::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.imgBorder {
  border: 1px solid #6c38e0;
}
.imgData {
  transition: 0.5s ease 0.1s;
  display: flex;
  flex-direction: column;
}
.imgData img {
  margin: 6px 0;
}
</style>
