<template>
  <!-- 产品展示区 -->
  <div class="live">
    <!-- 头像区域 -->
    <div class="imgVideo">
      <video id="video" class="head" autoplay="autoplay" @ended="videoEnded">
        <source type="video/mp4" />
        <source type="video/ogg" />
      </video>
      <loding
        :isShow="loading"
        :text="loadingText"
        :className="'className'"
      ></loding>
      <loding
        :isShow="showOverall"
        :text="loadingOverall"
        :className="'className'"
      ></loding>
    </div>
    <!-- 对话区域 -->
    <div class="chat" id="chatroom">
      <div v-for="(item, inx) in textList" :key="inx" class="msgHtml">
        <b>{{ item.name }}:</b><span>{{ item.text }}</span>
      </div>
    </div>
    <!-- 输入区域 -->

    <div class="import">
      <div class="gifImport" v-if="record">
        <img src="../../assets/img/aiVideo/gif.gif" alt="" />
      </div>
      <div :class="['tab', SessionType ? 'tab1' : '']">
        <div class="tabText1 pointer" @click="SetSessionType(false)">
          文本/语音驱动数字人
        </div>
        <div class="tabText2 pointer" @click="SetSessionType(true)">
          对接“Feitian大模型”人机对话
        </div>
      </div>
      <div class="inputDiv" :class="SessionType && 'inputDivradius'">
        <div class="icon_voice" @click="clickIconType">
          <img
            v-if="isShow"
            src="../../assets/img/aiVideo/texticon.png"
            alt=""
          />
          <i v-else class="el-icon-microphone"></i>
          <!-- <span>{{ isShow ? "文字" : "语音" }}</span> -->
        </div>
        <textarea
          ref="autoTextArea"
          rows="1"
          @input="adjustTextAreaHeight"
          placeholder="试试输入商品信息，进行“卖点生成”、“图片描述”"
          v-model="input"
          class="chat-input"
          v-if="!isShow"
          @keydown="handleKeyCode($event)"
        >
        </textarea>
        <div class="voice pointer" @click="clickRecord" v-else>
          <span v-if="!record">开始收音</span>
          <span v-else class="voice-finish">
            <span class="radius_border">
              <span class="border_solid"></span>
            </span>
            <span>结束收音</span>
          </span>
        </div>
        <el-button
          icon="el-icon-s-promotion"
          circle
          @click="handleKeyDown()"
          :disabled="isShow"
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
//必须引入的核心
import Recorder from 'recorder-core';

//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
// import "recorder-core/src/engine/mp3";
// import "recorder-core/src/engine/mp3-engine";
//录制wav格式的用这一句就行
import 'recorder-core/src/engine/wav';

//可选的插件支持项，这个是波形可视化插件
import 'recorder-core/src/extensions/waveview';

import { postUpload } from '../../api/aiVideo.js';
import loding from './loding.vue';

export default {
  components: { loding },
  props: {
    selectImgUrl: String,
    isShow: Boolean,
    SessionType: Boolean,
    SwitchRequestType: Function,
    SetSessionType: Function,
    textList: Array,
    AddTextList: Function,
    figureData: Object,
    pictLoading: Boolean,
    getVoice: Function,
    selectImgVideoUrl: String,
    loading: Boolean,
    isDigital: String,
    loadingOverall: Object,
    showOverall: Boolean,
  },

  data() {
    return {
      input: '',
      record: false,
      rec: null,
      secondAdd: null, // 将 secondAdd 声明为成员变量2
      loadingText: {
        text: '人脸检测',
        finish: '人脸驱动模型处理...',
      },
    };
  },
  mounted() {
    this.recOpen();
  },
  methods: {
    // 选择语音或文字
    clickIconType() {
      if (this.record) {
        return;
      }
      this.SwitchRequestType(!this.isShow);
    },
    // 开始录音
    clickRecord() {
      if (!this.record) {
        this.record = true;
        this.recStart();
        this.secondAdd = setTimeout(() => {
          clearTimeout(this.secondAdd); // 使用 this 访问成员变量
          console.log('20秒到了，暂停录音');
          this.recStop();
          this.record = false;
        }, 20000);
      } else {
        console.log('暂停录音触发');
        this.record = false;
        clearTimeout(this.secondAdd); // 使用 this 访问成员变量
        this.recStop();
      }
    },
    // 发送消息
    handleKeyDown() {
      const chatContainer = document.getElementById('chatroom');
      let data = {
        name: '用户',
        text: this.input,
        qa: this.SessionType && 1,
      };
      if (!!this.input.trim().length) {
        this.AddTextList(data);
        chatContainer.scrollButton = 0;
        this.input = '';
      } else {
        this.$message({
          message: '请输入内容',
          type: 'warning',
          center: true,
          offset: 100,
        });
      }
    },
    // 键盘事件
    handleKeyCode(event) {
      if (event.shiftKey && event.keyCode == 13) {
        this.input = this.input;
      } else if (event.keyCode == 13) {
        if (!event.metaKey) {
          event.preventDefault();
          this.handleKeyDown();
        }
      }
    },
    // textarea响应式高度
    adjustTextAreaHeight() {
      const textarea = this.$refs.autoTextArea;
      textarea.style.height = 'auto'; // 重置高度以便重新计算
      textarea.style.height = `${textarea.scrollHeight - 7.5}px`;
      // console.log(textarea.scrollHeight);

      // 如果内容变少，确保<textarea>的高度不超过初始高度
      const computedStyle = window.getComputedStyle(textarea);
      const minHeight =
        parseInt(computedStyle.getPropertyValue('min-height'), 10) || 0;
      textarea.style.height = `${Math.max(textarea.scrollHeight, minHeight)}px`;
    },
    //获取录音权限
    recOpen() {
      //创建录音对象
      this.rec = Recorder({
        type: 'wav', //录音格式，可以换成wav等其他格式
        sampleRate: 16000, //录音的采样率，越大细节越丰富越细腻
        bitRate: 16, //录音的比特率，越大音质越好
        onProcess: (
          buffers,
          powerLevel,
          bufferDuration,
          bufferSampleRate,
          newBufferIdx,
          asyncEnd,
        ) => {
          //录音实时回调，大约1秒调用12次本回调
          //可实时绘制波形，实时上传（发送）数据
          if (this.wave)
            this.wave.input(
              buffers[buffers.length - 1],
              powerLevel,
              bufferSampleRate,
            );
        },
      });

      //打开录音，获得权限
      this.rec.open(
        () => {
          console.log('录音已打开');
          if (this.$refs.recwave) {
            //创建音频可视化图形绘制对象
            this.wave = Recorder.WaveView({ elem: this.$refs.recwave });
          }
        },
        (msg, isUserNotAllow) => {
          //用户拒绝了录音权限，或者浏览器不支持录音
          console.log(
            (isUserNotAllow ? 'UserNotAllow，' : '') + '无法录音:' + msg,
          );
        },
      );
    },
    // 开始录音
    recStart() {
      if (!this.rec) {
        console.error('未打开录音');
        return;
      }
      this.rec.start();
      console.log('已开始录音');
    },
    // 结束录音
    recStop() {
      if (!this.rec) {
        console.error('未打开录音');
        return;
      }
      this.rec.stop(
        (blob, duration) => {
          //blob就是我们要的录音文件对象，可以上传，或者本地播放
          this.recBlob = blob;
          //简单利用URL生成本地文件地址，此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
          var localUrl = (window.URL || webkitURL).createObjectURL(blob);
          console.log('录音成功', blob, localUrl, '时长:' + duration + 'ms');
          this.upload(blob); //把blob文件上传到服务器
        },
        (err) => {
          console.error('结束录音出错：' + err);
        },
      );
    },
    // 文件上传
    upload(blob) {
      //使用FormData用multipart/form-data表单上传文件
      //或者将blob文件用FileReader转成base64纯文本编码，使用普通application/x-www-form-urlencoded表单上传
      var form = new FormData();

      var dates = new Date();
      var times = dates.getTime();
      function generateRandomString(length) {
        let result = '';
        const characters =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charactersLength);
          result += characters.charAt(randomIndex);
        }

        return result;
      }

      const randomString = generateRandomString(5);
      form.append('file', blob, randomString + times + '.wav'); //和普通form表单并无二致，后端接收到file参数的文件

      postUpload(form)
        .then((response) => {
          let audioUrl = response.data.data.fileUrl;
          let data = {
            name: this.isShow ? '用户语音' : '用户',
            qa: this.SessionType ? 1 : 0,
            audioUrl: audioUrl,
          };

          // 处理成功响应
          this.getVoice(data);

          console.log('上传成功', response);
        })
        .catch((error) => {
          // 处理错误
          console.error('上传失败', error);
        });
    },
    // 视频播放结束
    videoEnded() {
      const video = document.getElementById('video');
      video.loop = 'loop';
      video.src = this.selectImgVideoUrl;
      video.play();
    },
  },
  // 监听属性
  watch: {},
  // 计算属性
  computed: {},
};
</script>
<style scoped>
.live {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}
.imgVideo {
  transition: opacity 0.2s;
  position: relative;
}
/* 内容区 */
.live .head {
  width: 300px;
  height: 300px;
  border-radius: 1000px;
  border: 1px solid #6c38e0;
  overflow: hidden;
}
.divVideo {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #6c38e0;
}
.loading {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: 2px solid #6c38e0;
  border-top-color: transparent;
  border-radius: 100%;

  animation: circle infinite 0.75s linear;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.live .chat {
  width: 50%;
  height: 300px;
  box-sizing: border-box;

  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  word-break: keep-all;
  overflow-wrap: break-word;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
}
.live .chat::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.live .import {
  width: 61%;
  position: relative;
}
.live .import .tab {
  display: flex;
  position: relative;
  background: url('../../assets/img/aiVideo/tab.png');
  background-repeat: no-repeat;
}
.tab1 {
  background: url('../../assets/img/aiVideo/tab1.png') !important;
  background-repeat: no-repeat !important;
}
.live .import .tab .tabText1 {
  width: 310px;
  height: 64px;
  overflow: hidden;
  box-sizing: border-box;
  line-height: 64px;
  padding-left: 32px;
  opacity: 0.8;
  font-family: PingFangSC-Medium;
  background-size: 100% 100%;
  font-size: 20px;
  color: #6c38e0;
  border-radius: 8px 50% 0 0;
  position: relative;
  bottom: -7px;
}
.colorWhite {
  color: white;
}
.live .import .tab .tabText2 {
  height: 64px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0 32px;
  font-family: PingFangSC-Medium;
  font-size: 20px;
  line-height: 64px;
  color: #000000;
}
.inputDiv {
  width: 70%;
  height: 80px;
  overflow: hidden;
  width: 100%;
  border-radius: 0 8px 8px 8px;
  opacity: 1;
  background: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  padding-right: 16px;
}
.inputDivradius {
  border-radius: 8px 8px 8px 8px;
}
.inputDiv .icon_voice {
  width: 96px;
  height: 40px;
  font-size: 22px;
  font-weight: normal;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: center;
  border-right: 1px solid #c5c5cb;
  color: #5d5f6c;
  cursor: pointer;
  margin-right: 24px;
}
.pointer {
  cursor: pointer;
}
.inputDiv /deep/.el-input__inner {
  height: 79px;
  height: 100%;
  background: rgba(255, 255, 255, -1);
  border-radius: 0;
  border: none;
}
.inputDiv .voice {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputDiv .voice-finish {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputDiv .voice .radius_border {
  width: 19.8px;
  height: 19.8px;
  border-radius: 100px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #d04034;
  box-sizing: border-box;
  margin-right: 5px;
}

.inputDiv .voice .radius_border .border_solid {
  width: 8.49px;
  height: 8.49px;
  border-radius: 100px;
  opacity: 1;
  background: #d04034;
}
.import .gifImport {
  display: flex;
  justify-content: center;
  /* margin-bottom: 10px; */
  position: absolute;
  z-index: 111;
  top: -110px;
  left: 50%;
  transform: translateX(-50%);
}
.import .gifImport img {
  width: 94px;
}
.chat-input {
  position: relative;
  padding-left: 24px;
  flex: 1;
  border: 0;
  font-size: 16px;
  color: rgba(93, 95, 108, 1);
  background: transparent;
  outline: none;
  max-height: 100px;
  resize: none;
  box-sizing: border-box;
  padding-right: 24px;
}
</style>
