<template>
  <div class="AiVideo">
    <Viewheader theme="light" />
    <videoConter></videoConter>
  </div>
</template>

<script>
import videoConter from "../../components/video_ai/videoConter.vue";
import Viewheader from "../../components/viewheader/viewheader.vue";
export default {
  components: {
    Viewheader,
    videoConter,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.AiVideo {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/img/aiVideo/bg.png");
  background-size: 100% 100%;
}
</style>
