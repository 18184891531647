import request from "@/router/axios";
// 2D 数字人生成
export const postChatSse = (data) => {
  return request({
    url: "/api/video/digital2DPeopleGenerating",
    method: "post",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
      // isSample: "0",
    },
    data: {
      ...data,
    },
  });
};
// 2D 数字人生成
export const postPilotModel = (data) => {
  return request({
    url: "/api/text/chatSseg",
    method: "post",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
      // isSample: "0",
    },
    data: {
      ...data,
    },
  });
};
// 上传文件
export const postUpload = (data) => {
  return request({
    url: "/api/upload/uploadFile",
    method: "post",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
      mimeType: "multipart/form-data",

      // isSample: "0",
    },
    data,
  });
};
//获取待机视频
export const standbyModeUrl = (data) => {
  return request({
    url: "api/video/digital2DPeopleWait",
    method: "post",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
      // isSample: "0",
    },
    data,
  });
};
