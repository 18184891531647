<template>
  <div class="content">
    <div :class="[SessionType ? 'mainBg' : '', 'main']">
      <div class="centre" v-if="isDigital === '2D'">
        <div class="centre-top">
          <div class="left-1">
            <div
              :class="[isDigital === '2D' && 'topColor', 'mr-40', 'pointer']"
              @click="switchDigital('2D')"
            >
              {{ titleText.title }}
            </div>
            <!-- <div
              :class="[
                SessionType && 'colorWhite',
                isDigital === '3D' && 'topColor',
                'pointer',
              ]"
              @click="switchDigital('3D')"
            >
              3D数字人物建模+语音驱动
            </div> -->
          </div>
          <span
            :class="[SessionType && 'colorWhite', 'right-1']"
            @click="emptyTextList"
            >清空记录</span
          >
        </div>
        <div class="centre-content">
          <videoImfList
            :imgData="imgData"
            :action="action"
            :SessionType="SessionType"
            :imageUrl="imageUrl"
            :setSelectImgUrl="setSelectImgUrl"
            :handleAvatarSuccess="handleAvatarSuccess"
            :loading="loading"
          ></videoImfList>
          <VideoLive
            :selectImgUrl="selectImgUrl"
            :isShow="isShow"
            :SessionType="SessionType"
            :SwitchRequestType="SwitchRequestType"
            :SetSessionType="SetSessionType"
            :textList="textList"
            :AddTextList="AddTextList"
            :figureData="figureData"
            :getVoice="getVoice"
            :selectImgVideoUrl="selectImgVideoUrl"
            :loading="loading"
            :isDigital="isDigital"
            :loadingOverall="loadingText"
            :showOverall="pictLoading"
          ></VideoLive>
        </div>
      </div>

      <digitalHuman
        v-else
        :isDigital="isDigital"
        :SessionType="SessionType"
        :switchDigital="switchDigital"
        :titleText="titleText"
      ></digitalHuman>
      <loding :isShow="pictLoading" :text="{}"></loding>
    </div>
  </div>
</template>

<script>
const model1 =
  'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/20231023-161722.jpg';
const model8 =
  'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/微信图片_20231018140304.png';
const model7 =
  'https://ai-algorithm-data.s3.cn-north-1.amazonaws.com.cn/dw/livestream/kehu4.jpg';
const model2 = 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/1.png';
const model3 =
  'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/asdasd2.jpg';
const model4 = 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/3.jpg';
const model5 = 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/4.jpg';
import { apiUri } from '../../api/txt';
import { postChatSse, standbyModeUrl } from '../../api/aiVideo';
import videoImfList from './videoImfList.vue';
import VideoLive from './VideoLive.vue';
import digitalHuman from './digitalHuman.vue';
import loding from './loding.vue';
export default {
  components: { videoImfList, VideoLive, digitalHuman, loding },
  data() {
    return {
      imageUrl: '',
      select: 0,
      titleText: {
        title: '2D数字人',
        title1: '3D数字人物建模+语音驱动',
      },
      video: null,
      imgData: [model1, model2, model3, model4, model5, model8, model7],
      selectImgUrl: model1,
      selectImgVideoUrl: null,
      refDiv: null,
      action: `${apiUri}/upload/uploadFile`,
      isShow: false,
      SessionType: false, // 对话类型
      textList: [],
      figureData: {},
      pictLoading: false,
      isDigital: '2D',
      loading: false,
      history: [],
      uploadLoading: false,
      loadingText: {
        text: '文本 / 语音数据转换',
        text2: '模型输入数据准备…',
        text3: '模型加载',
        finish: '照片驱动文件生成...',
      },
      jiangxue: {
        loadingVideo:
          'https://ai-algorithm-data.s3.cn-north-1.amazonaws.com.cn/dw/livestream/20231022142502548539_driving1.mp4',
        video:
          'https://ai-algorithm-data.s3.cn-north-1.amazonaws.com.cn/dw/livestream/20231023145439997310.mp4',
        img: model1,
      },
    };
  },
  mounted() {
    const video = document.getElementById('video');
    video.muted = 'muted';
    this.setSelectImgUrl(model1, 'mounted');
    this.getlocalStorage();
  },
  // 监听属性
  watch: {
    isDigital: {
      handler(newVal, oldVal) {
        const _this = this;
        if (newVal === '2D') {
          setTimeout(() => {
            const video = document.getElementById('video');
            video.muted = 'muted';
            video.src = _this.selectImgVideoUrl;
            video.loop = 'loop';
            video.play();
          }, 500);
        }
      },
    },
  },
  methods: {
    // 图片上传成功
    handleAvatarSuccess(res, file) {
      if (!!res.data && res.state === '0x0000') {
        this.imgData.splice(1, 0, res.data.fileUrl);
      } else {
        this.$message.error({
          message: res.message,
          center: true,
          offset: 100,
          onClose: () => {
            if (res.state === '0x0008') {
              this.$router.push({
                path: '/logIn/register',
              });
            }
          },
        });
      }

      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 被选中图片
    async setSelectImgUrl(imgUrl, inx) {
      this.loading = true;
      const video = document.getElementById('video');
      this.video = video;

      if (inx === 0) {
        video.muted = false;
        this.figureData.videoUrl = this.jiangxue.video;
        this.selectImgVideoUrl = this.jiangxue.loadingVideo;
        this.selectImgUrl = this.jiangxue.img;
        video.loop = '';
        video.src = this.jiangxue.video;
        video.play();
        this.loading = false;
        return;
      }
      if (inx === 'mounted') {
        video.muted = 'muted';
        this.figureData.videoUrl = this.jiangxue.loadingVideo;
        this.selectImgVideoUrl = this.jiangxue.loadingVideo;
        this.selectImgUrl = this.jiangxue.img;
        video.loop = 'loop';
        video.src = this.jiangxue.loadingVideo;
        video.play();
        this.loading = false;
        return;
      }
      video.loop = 'loop';
      const res = await standbyModeUrl({ imgUrl });
      if (!!res.data.data && res.data.state === '0x0000') {
        this.selectImgUrl = imgUrl;
        video.src = res.data.data.videoUrl;
        video.muted = false;
        video.play();
        this.selectImgVideoUrl = res.data.data.videoUrl;
        this.figureData.videoUrl = res.data.data.videoUrl;
        this.loading = false;
      } else {
        this.$message.error({
          message: res.data.message || res.data.msg,
          center: true,
          offset: 100,
        });
        this.loading = false;
        this.selectImgUrl = imgUrl;
      }
    },
    // 切换语音或文字
    SwitchRequestType(is) {
      this.isShow = is;
    },
    // 切换语音或文字
    SwitchRequestType(is) {
      this.isShow = is;
    },
    // 语言类型
    SetSessionType(is) {
      if (is) {
        this.isShow = !is;
      }
      this.SessionType = is;
    },
    // 添加聊天内容
    AddTextList(data) {
      if (!data.audioUrl) {
        this.GenerateChat(data);
      }
      this.getChatText(data);
    },
    // 获取消息 生成2D数字人
    async getChatText(data) {
      const video = document.getElementById('video');
      video.muted = false;
      this.pictLoading = true;
      const dataList = {
        imgUrl: this.selectImgUrl,
        text: data.text,
        audioUrl: data.audioUrl,
        qa: data.qa ? 1 : 0,
        history: data.qa ? this.history : [],
      };
      const res = await postChatSse(dataList);
      if (!!res.data.data) {
        // 存储历史记录
        if (!!res.data.data.chatText) {
          this.history.push({
            question: res.data.data.text,
            answer: res.data.data.chatText,
          });
          if (this.history.length >= 5) {
            this.history.shift();
          }
          this.setlocalStorage(this.history);
        }

        this.figureData = {
          name: '数字人',
          ...res.data.data,
        };
        video.src = res.data.data.videoUrl;
        video.loop = false;
        video.play();
        this.pictLoading = false;
        if (data.qa === 1 || res.data.data.chatText) {
          this.GenerateChat(this.figureData);
        }
      } else {
        this.$message.error({
          message: res.data.message || res.data.msg,
          center: true,
          offset: 100,
        });
        this.pictLoading = false;
      }
    },
    // 发送语音消息 生成数字人
    async getVoice(data) {
      this.pictLoading = true;

      const video = document.getElementById('video');
      video.muted = false;
      const dataList = {
        imgUrl: this.selectImgUrl,
        audioUrl: data.audioUrl,
        qa: data.qa ? 1 : 0,
        history: data.qa ? this.history : [],
      };
      const res = await postChatSse(dataList);
      if (!!res.data.data) {
        this.figureData = {
          name: this.isShow ? '用户语音' : '用户',
          ...res.data.data,
        };
        this.pictLoading = false;
        video.src = res.data.data.videoUrl;
        video.loop = false;
        this.pictLoading = false;
        this.GenerateChat(this.figureData);
        // 存储历史记录
        if (!!res.data.data.chatText) {
          this.history.push({
            question: res.data.data.text,
            answer: res.data.data.chatText,
          });
          if (this.history.length >= 5) {
            this.history.shift();
          }
          this.setlocalStorage(this.history);
        }
      } else {
        this.$message.error({
          message: res.data.message || res.data.msg,
          center: true,
          offset: 100,
        });
        this.pictLoading = false;
      }
    },
    // 输出聊天记录
    GenerateChat(data) {
      console.log(data, 'data');
      const user = `<div class="mb-2 msgHtml"><p>${data.name}: ${data.text}</p></div>`;
      const chat = `<div class="mb-2 msgHtml"><p>${
        !!data.chatText && '数字人'
      }: ${data.chatText}</p></div>`;
      const msgHtml = `
      ${data.name === '用户' || data.name === '用户语音' ? user : ''}
      ${!!data.chatText ? chat : ''}
      `;
      const chatContainer = document.getElementById('chatroom');
      if (chatContainer) {
        chatContainer.innerHTML += msgHtml;
      }
      chatContainer.scrollTop = chatContainer.scrollHeight;
    },
    // 清空聊天内容
    emptyTextList() {
      const chatContainer = document.getElementById('chatroom');
      chatContainer.innerHTML = '';
      this.history = [];
      localStorage.setItem('myData', JSON.stringify(this.history));
    },
    switchDigital(name) {
      this.isDigital = name;
    },
    // 储存localStorage
    setlocalStorage(data) {
      console.log('Storag储存了');
      localStorage.setItem('myData', JSON.stringify(data));
    },
    // 获取localStorage
    getlocalStorage() {
      const retrievedData = localStorage.getItem('myData');
      // 如果之前存储的数据存在，则使用 JSON.parse() 方法将其转换回对象
      if (retrievedData) {
        const parsedData = JSON.parse(retrievedData);

        this.history = parsedData;
      }
    },
  },

  // 计算属性
  computed: {},
};
</script>
<style scoped>
.content {
  width: 100%;
  height: calc(100% - 80px);
  box-sizing: border-box;
  padding-left: 4.1%;
  padding-right: 4.1%;
  padding-bottom: 2.1%;
}
.main {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/img/aiVideo/bgPath.png');
  background-size: 100% 100%;
  position: relative;
}
.mainBg {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/img/aiVideo/mainBg.png');
}
.centre {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(
    124deg,
    rgba(255, 255, 255, 0.8) 17%,
    rgba(255, 255, 255, 0.6) 81%
  );
  border-radius: 16px;
  opacity: 0.88;
  box-sizing: border-box;
  padding: 30px 40px;
}
.centre-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: PingFangSC-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  margin-bottom: 32px;
}
.left-1 {
  display: flex;
}
.mr-40 {
  margin-right: 40px;
}
.topColor {
  color: #6c38e0;
}
.right-1 {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  cursor: pointer;
  color: #5d5f6c;
}
.right-1:hover {
  color: #0221eb;
}
.centre-content {
  display: flex;
  flex: 1;
  height: calc(100% - 38px);
}
.pointer {
  cursor: pointer;
}
</style>
